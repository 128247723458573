export const environment = {
  production: false,
  ipgApiUrl: 'https://cg-cpl-api.nt-development.dev/ipg',
  gcPayApiUrl: 'https://cg-cpl-api.nt-development.dev/pay',
  apiUrl: 'https://cg-cpl-api.nt-development.dev/api',
  authConfig: {
    issuer: 'https://cg-auth-api.nt-development.dev',
    redirectUri: window.location.origin,
    clientId: 'coinigo-localhost-cpl',
    responseType: 'code',
    scope: 'email openid profile roles offline_access',
    dummyClientSecret: '123456789',
    skipIssuerCheck: true
  }
};
